<template>
  <div>
    <div v-for="(formulario, index) in $parent.formularios" :key="index + 1">
      <div
        class="row shadow alert-default-primary rounded border border-primary mt-3"
      >
        <div class="col-md-11">
          <div class="container testimonial-group">
            <div class="row text-center flex-nowrap">
              <div class="form-group col-md-2">
                <label for="bloque">Tipo Bono</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form[index].tipo_bono"
                  :class="form[index].tipo_bono ? 'is-valid' : 'is-invalid'"
                >
                  <option
                    v-for="tipo_bono in $parent.$parent.listasForms.tipos_bonos"
                    :key="tipo_bono.numeracion"
                    :value="tipo_bono.numeracion"
                  >
                    {{ tipo_bono.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="bloque">Temporada</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form[index].temporada"
                  :class="form[index].temporada ? 'is-valid' : 'is-invalid'"
                >
                  <option
                    v-for="temporada in $parent.$parent.listasForms.temporadas"
                    :key="temporada.numeracion"
                    :value="temporada.numeracion"
                  >
                    {{ temporada.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="bloque">Tipo Ruta</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form[index].tipo_ruta"
                  :class="form[index].tipo_ruta ? 'is-valid' : 'is-invalid'"
                >
                  <option
                    v-for="tipo_ruta in $parent.$parent.listasForms.tipos_ruta"
                    :key="tipo_ruta.numeracion"
                    :value="tipo_ruta.numeracion"
                  >
                    {{ tipo_ruta.descripcion }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-2">
                <label for="bloque">Sitio</label>
                <v-select
                  v-model="form[index].sitio"
                  placeholder="Sitios"
                  label="nombre"
                  multiple
                  :options="$parent.$parent.listasForms.sitios"
                  class="form-control form-control-sm p-0"
                  :class="
                    form[index].sitio && form[index].sitio.length > 0
                      ? 'is-valid'
                      : 'is-invalid'
                  "
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label for="bloque">Sitio Destino</label>
                <v-select
                  v-model="sitio_destino[index]"
                  placeholder="Sitio Destino"
                  label="nombre"
                  :options="$parent.$parent.listasForms.sitios"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Monto</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form[index].monto"
                  :class="form[index].monto ? 'is-valid' : 'is-invalid'"
                >
                  <option
                    v-for="monto in $parent.montos"
                    :key="monto.numeracion"
                    :value="monto.numeracion"
                  >
                    {{
                      "$ " +
                        parseFloat(monto.descripcion).toLocaleString("es-ES")
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Observación</label>
                <textarea
                  class="form-control form-control-sm"
                  v-model="form[index].observacion"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1 mt-1">
          <div class="btn-group float-right">
            <button
              class="btn bg-gradient-success shadow mt-4"
              @click="save(index)"
              v-if="
                form[index].tipo_bono &&
                  form[index].temporada &&
                  form[index].tipo_ruta &&
                  form[index].sitio &&
                  form[index].sitio.length > 0 &&
                  form[index].monto
              "
            >
              <i class="fas fa-save"></i>
            </button>
            <button
              class="btn bg-gradient-danger shadow mt-4"
              v-show="form[index].id"
              @click="destroy(index, form[index].id)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "ProgramacionBonoModalDetalle",
  components: {
    vSelect,
  },
  data() {
    return {
      sitio_destino: [],
      form: [],
    };
  },

  methods: {
    async save(index) {
      this.form[index].programacion_bono_id = this.$parent.form.id;
      this.form[index].fecha_ini = this.$parent.form.fecha_ini;
      this.form[index].fecha_fin = this.$parent.form.fecha_fin;
      this.form[index].estado = 1;
      await axios
        .post("/api/hidrocarburos/detProgramacionBono", this.form[index])
        .then(async (response) => {
          if (!this.form[index].id) {
            let new_form = {
              id: null,
              sitio: null,
              programacion_bono_id: null,
              fecha_ini: null,
              fecha_fin: null,
              temporada: null,
              tipo_ruta: null,
              tipo_bono: null,
              monto: null,
              observacion: null,
            };
            this.form.push(new_form);
            await this.$parent.addForm(this.form[index]);
          }
          this.form[index].id = await response.data;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },

    async destroy(index_form, form_id) {
      this.$swal({
        title: "Está seguro de eliminar este detalle?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          //this.$parent.cargando = true;
          await axios
            .delete("/api/hidrocarburos/programacionBono/" + form_id)
            .then(async (response) => {
              // Si se encuentra el elemento, elimínalo del array
              if (index_form !== -1) {
                this.form.splice(index_form, 1);
                this.sitio_destino.splice(index_form, 1);
                await this.$parent.formularios.splice(index_form, 1);
                await this.$parent.formularios.forEach((formulario, index) => {
                  this.$parent.formularios[index].posicion = index + 1;
                });
                this.$parent.cant--;
              }
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    selectSitioDestino(index) {
      this.form[index].sitio_destino_id = null;
      if (this.sitio_destino[index]) {
        this.form[index].sitio_destino_id = this.sitio_destino[index].id;
      }
    },
  },

  async beforeMount() {
    await this.$parent.formularios.forEach((formulario, index) => {
      this.form[index] = formulario.form;
      this.sitio_destino[index] = this.$parent.$parent.listasForms.sitios.find(
        (sitio) => sitio.id === formulario.form.sitio_destino_id
      );
    });
  },
};
</script>

<style>
/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  scrollbar-color: rgb(238, 157, 7) #fff6459a;
  white-space: nowrap;
}
.testimonial-group > .row > .col-sm-4 {
  display: inline-block;
  float: none;
}
</style>
